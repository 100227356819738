.workConent {
    font-size: 13px;
    max-height: 700px;
    overflow-x: auto;
    overflow-y: auto;
}

.workConent img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.workConent p {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  min-height: 20px;
}

.workConent table {
  border: 1px double #b3b3b3;
  border-collapse: collapse;
  border-spacing: 0;
}

.workConent table th {
  font-weight: 700;
  background: hsla(0, 0%, 0%, 5%);
}

.workConent table th,td {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #b3b3b3;
}

.workConent ul {
  list-style: disc !important;
}

.workConent ol {
  list-style: decimal !important;
}


.workConent blockquote {
  margin-right: 0;
  margin-left: 0;
  padding-right: 1.5em;
  padding-left: 1.5em;
  overflow: hidden;
  font-style: italic;
  border-left: 5px solid #ccc;
}

.workConent figure {
  margin: 0;
}

.workConent figure img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.workConent figure[class='image'] {
  display: table;
  clear: both;
  text-align: center;
}

.workConent figure[class*='image-style-side'] {
  float: right;
  max-width: 50%;
  margin-left: var(--ck-image-style-spacing);
}